.png-sprite{
  &:before{
    content: '';
    display: inline-block;
  }

  &--html5:before{
    .sprite(@sprite-html5);
  }

  &--html5-retina:before{
    .retina-sprite(@sprite-html5-group)
  }
}