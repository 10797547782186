// Extra

p,span,h1,h2,h3,h4,h5,h6,a,li,label,input,form,textarea,select,option,div,button{
  font-family: 'Roboto', sans-serif;
}
body{
  color: @lsv_grey_very_dark;
  font-size: 15px;
}
a{
  color: inherit;
  text-decoration: none;
  &:active, &:focus, &:hover,&:hover.active{
    color: inherit;
    text-decoration: none;
  }
}
textarea:focus,textarea:active{
  outline-width: 0px;
  border: 1px solid @lsv_green;
}
hr{
  color: @lsv_grey_very_dark;
  border-top: 1px solid @lsv_grey_very_dark;
}
select::-ms-expand {
    display: none;
}
select{
  // -webkit-appearance: none!important;
  // -moz-appearance: none!important;
  -webkit-appearance: none;
     -moz-appearance: none;
      -ms-appearance: none;
       -o-appearance: none;
          appearance: none;
}
.mobile_scroll_wrap.active{
  overflow: hidden;
}
.resetter{
  position: relative;
}
main{
 z-index: 1020; 
}
.nomargin{
  margin: 0;
}
.nopadding{
  padding: 0;
}
.padding-40pmh{
  padding: 40px + @lsv_menu_height 40px 40px 40px;
}
.padding-40-40-40-0pmh{
  padding: 40px + @lsv_menu_height 40px 40px 0px;
}
.padding-40{
  padding: 40px;
}
.padding-mh{
  padding-top: @lsv_menu_height;
}
.padding-40-0{
  padding: 40px 0px;
}
.padding-0-40{
  padding: 0 40px;
  div{
    padding: 0;
  }
}
.margin-40-0{
  margin: 40px 0;
}
.margint-20{
  margin-top: 20px;
}
.margint-40{
  margin-top: 40px;
}
.margint-80{
  margin-top: 80px;
}
.margint-150{
  margin-top: 150px;
}
.head-displace{
  top: @lsv_menu_height;
}
.mb50{
  margin-bottom: 50px;
}
.fullscreen{
  height: 100%;
}
.align_container{
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
}
.underline{
  text-decoration: underline;
}
.lsv-green-bg{
  background-color: @lsv_green;
}
.fullheight-container{
  height: 100%;
}
.lsv-mobile-hide{
  @media screen and (max-width: 480px){
    display: none;
  }
}
.lsv-desktop-hide{
  @media screen and (min-width: 481px){
    display: none;
  }
}
.lsv-only-desktop{
  @media screen and (max-width: 992px){
    display: none;
  }
}
#dont-rotate{
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: @lsv_green;
  text-align: center;
  z-index: 4000;
  color: white;
  justify-content: center;
  flex-direction: column;
  display: none;
}
/* 
1024
 */
 a{
  &:hover{
    color: inherit;
  }
 }
 textarea{
  resize: vertical;
 }
.lsv-nav{
  z-index: 2004;
  background-color: @lsv_menu_color;
  height: @lsv_menu_height;
}



.lsv-nav--valign{
  position: absolute;
  top: 0;
  bottom: 0;
  left: @lsv_logo_displace;
  width: 0;
  height: 67%;
  margin: auto;
}

.lsv-nav__logo{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 46%;
  width: 0;
  height: 67%;
  margin: auto;

  @media screen and (max-width: 800px){
    left: @lsv_menu_height*2 + 30px;
  }
  @media screen and (max-width: 420px){
    left: @lsv_menu_height + 20px;
  }
}
.btn-konkurs{
  position: absolute;
  background-color: #188d08;
  left: 124px;
  border: 0;
  box-shadow: none;
  outline: none;
  color: white;
  text-align: left;
  line-height: 1;
  padding: 16px;
  transition: all 500ms;
  @media screen and (max-width: 800px){
    left: 0;
    top: 62px;
    padding: 16px 15px;
  }
  span{
    display: inline-block;
    vertical-align: top;
  }
  .icon{
    display: inline-block;
    transition: all 500ms;
    margin-right: 0;
    transform: rotate3d(0,1,0,0deg);

  }
  &:hover{
    background-color: #1ca60a;
    .icon{
      margin-right: 10px;
      transform: rotate3d(0,1,0,360deg);
    }
  }
}

.lsv-nav__menu-btn{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: @lsv_menu_height*2;
  border: 0;
  padding: 0;
  background-color: @lsv_green;
  @media screen and (max-width: 420px){
    width: @lsv_menu_height;
  }
  &:focus{
    outline: none;
  }

  &:hover{
    background-color: @lsv_green_active;
  }
  &:hover .line{
    // transform: translate3d(0,-7px,0);
    top: -7px;
  }
  &:hover .line01{
    opacity: 0;
    transition-delay: 0ms;
  }
  &:hover .line02{
    transition-delay: @lsv_menu_btn_delay;
  }
  &:hover .line03{
    transition-delay: @lsv_menu_btn_delay*2;
  }
  &:hover .line04{
    opacity: 1;
    transition-delay: @lsv_menu_btn_delay*3;
  }
  i{
    color: white;
    font-size: 24px;
  }
}

.lsv-nav__menu-btn--active{
  background-color: @lsv_green_active;
  transition-delay: 0ms;
  &:hover .line {
    top: -7px;
  }
  & .line{
    top: -7px;
  }
  & .line01{
    opacity: 0;
  }
  & .line02{
    opacity: 1;
    transform: translate3d(0,7px,0) rotate(-45deg);
    transform-origin: center center 0px;
  }
  & .line03{
    opacity: 0;
  }
  & .line04{
    opacity: 1;
    transform: translate3d(0,-7px,0) rotate(45deg);
    transform-origin: center center 0px;
  }
}

.bars-container {
    position: relative;
    backface-visibility: hidden;
}
.lsv-menu-btn__text{
  color: white;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  position: absolute;
  right: 0;
  margin: 20px 16px;
  margin-left: 0;
  top: 0;
  bottom: 0;
  display: block;
  @media screen and (max-width: 420px){
    display: none;
  }
}
.line {
    display: block;
    position: relative;
    top: 2px;
    height: 3px;
    width: 25px;
    margin: 4px 20px;
    background-color: #fff;
    transition: all 300ms cubic-bezier(.215,.61,.355,1);
    // transform: translate3d(0,0,0);
    
}

.line01{
  transition-delay: @lsv_menu_btn_delay*3;
}
.line02{
  transition-delay: @lsv_menu_btn_delay*2;
  
}
.line03{
  transition-delay: @lsv_menu_btn_delay;
}
.line04{
  opacity: 0;
  transition-delay: 0ms;
}

.lsv-nav__links{
  float:right;
  margin: 9px 13px;

  &.lsv-ishop{
    @media screen and (max-width: 615px){
      display: none;
    }
  }
}

.lsv-btn{
  color: white;
  border-radius: 40px;
  background-color: @lsv_grey;
  padding-top: 10px!important;
  padding-bottom: 10px!important;
  border: 0;
  &:hover, &:active, &:focus, &:active.focus, &.active.focus,&:focus.active{
    background-color: @lsv_green;
    border: 0;
    outline-width: 0;
  }
  span,i{
    color: white;
    font-size: 18px!important;
  }
  transition: all 500ms cubic-bezier(.215,.61,.355,1);
  &.btn[disabled="disabled"]{
    background-color: @lsv_green;
    color:white;
    &:hover{
      background-color: @lsv_green_active;
      color:white;
    }
  }
}
.lsv-btn--green{
  background-color: @lsv_green;
  &:hover{
    background-color: @lsv_green_active;
  }
}
.lsv-form__btn-submit{
  margin: 38px 0 73px;
  padding: 15px 30px;
}

.lsv-menu{
//  position: absolute;
  width: 100%;
  height: @lsv_float_menu_height;
  top: @lsv_menu_height - @lsv_toggle_menu_height;
  background-color: @lsv_green;
  color:white;
  transition: all 500ms cubic-bezier(.215,.61,.355,1);
  z-index: 2003;
  .container, .row{
    margin: 0;
    padding: 0 12px;
    width: 100%;
  }
  @media screen and (max-width: 1024px) {
      display: none;
  }
}

.lsv-menu--active{
  top: @lsv_menu_height;

}

.lsv-menu__list, .lsv-menu__sublist{
  display: block;
  list-style-type: none;
  -webkit-padding-start: 0;
  padding: 25px 0px;
}
.lsv-menu__sublist-item{
}

.lsv-menu__list{
}

.lsv-menu__list-item{
  padding-top: 0px;
  padding-right: 0;
  display: inline-block;
  border-right: 1px solid #56ad3c;
  height: @lsv_float_menu_height;

  label{
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 400;
  }
  a{
    font-size: 16px;
  }
  &:last-child{
    border: 0;
  }
}

.lsv-menu__link{
  text-decoration: underline;
}

.lsv-menu__link , .lsv-menu__sublink{
  
  color: white;
  &:hover{
    text-decoration: underline;
  }
}
#lsv-main__video{

  iframe{
    z-index: 1019;
    position: fixed;
    top: -15%;
    bottom: -10%;
    height: 120%;
    left: -10%;
    width: 120%;
  }
  h1{
    z-index: 1020;
    position: relative;
    color: white;
    font-size: 40px;
    text-transform: uppercase;
    text-align: center;
  }
}
.lsv-menu__sublist--footer{
  padding-left: 40px;
  padding-right: 40px;
  line-height: 2em;
  min-height: 240px;
  @media screen and (max-width: 992px){
    padding-bottom: 10px;
    font-size: 14px;
    font-weight: 300;
  }
  @media screen and (max-width: 420px){
    padding: 10px;
    margin: 0;
    min-height: 218px;
  }
}
@media screen and (max-width: 420px){
  .lsv-menu__sublist--footer:nth-last-child(2){
    min-height: 180px;
  }
}


.lsv-menu__sublist-item--footer{
  a{color: #666;}
}
.lsv-copyright{
  padding: 25px 20px;
  line-height: 2em;
  @media screen and (max-width: 992px){
    padding-bottom: 10px;
    font-size: 14px;
    font-weight: 300;
  }
  @media screen and (max-width: 420px){
    padding: 10px;
  }
}
.lsv-footer{
  background-color: @lsv_grey_light
}
.lsv-email{
  padding-top: 15px;
  text-decoration: underline;
}
.mrbrooks-copyright{
  background-color: @lsv_grey;
  
  p{
    padding: 32px;
    top: 0;
    bottom: 0;
    margin: auto;
    text-align: center;
    @media screen and (max-width: 420px){
      padding: 10px;
    }
  }

}
.menu-icon{
    transition: all 500ms cubic-bezier(.215,.61,.355,1);
}
.lsv-social{
  padding:17px 8px;
  margin: -1px;
  font-size: 18px;
  border-left: 1px solid @lsv_grey_light;
  border-right: 1px solid @lsv_grey_light;
  a {
    margin: 0 6px;
  }
  a:hover > svg > .menu-icon{
    fill: @lsv_green;
  }
  .vk-svg-icon{
    position: relative;
    top: -3px;
  }
  @media screen and (max-width: 420px) {
    border: 0;
  }
}
.lsv-btn--shop{
  background-color: transparent;
  border: 0;
  color: #9e9e9e;
  margin-top: 3px;
  transition: all 500ms cubic-bezier(.215,.61,.355,1);

  &:hover{
    background-color: transparent;
    color: @lsv_green;
  }
  &:hover > svg > .menu-icon{
    fill: @lsv_green;
  }
  & > svg{
    position: relative;
    top: 3px;
    left: -3px;
  }
}


.lsv-social--footer{
  font-size: 20px;
  margin: 0;
  .fa-circle{
    color: @lsv_green;
  }
  .fa-stack-1x{
    font-size: 20px;
  }
  a{
    margin: 0 -3px;
  }
  span{
    display: block;
  }
  @media screen and (min-width: 992px){
    padding: 20px 0;
    font-size: 14px;
    .fa-stack-1x{
      font-size: 15px;
    }
  }
  @media screen and (max-width: 420px) {
    padding: 20px 0;
  }
}

.lsv-h1{
  text-transform: uppercase;
  color: @lsv_green;
  font-size: 24px;
}
.lsv-select{
  -webkit-appearance: none;
   position: relative;
   overflow: hidden;
   display: inline-block;
   width: 140px;
   padding: 5px;
   font-size: 16px;
   line-height: 1;
   border: 0;
   -webkit-border-radius: 0;
   height: 45px;
   background-color: #fff;
   // background-color: transparent;
   background: url(../img/svg/arrow.svg) right no-repeat;
   // &:after{
   //  content: "";
   //  position: absolute;
   //  background: url(/img/svg/arrow.svg) right top no-repeat;
   //  display: block;
   //  width: 30px;
   //  height: 10px;
   //  float: right;
   //  margin: 0 0 0 30px;
   // }
   &:focus{
      box-shadow: none;
      border: 1px solid @lsv_green;
      outline-width: 0;
    }
  }
.lsv-news__header{
  padding: 20px + @lsv_menu_height 45px 20px;
  label{
    font-weight: 400;
  }
  h1, form{
    display: inline-block;
  }
  form{
    float: right;
    label{
      text-transform: uppercase;
    }
    *{
      margin-left:25px;
    }
  }
  @media screen and (max-width:768px){
    h1{
      width: 100%;
    }
    label{
      width: 100%;
    }
    .form-group{
      margin: 0;
    }
    label, select{
      margin: 0;
    }
  }
  @media screen and (max-width: 420px){
    padding: 30px + @lsv_menu_height 17px 20px;
  }
}
.lsv-news__content{
  padding: 0 40px 40px 40px;
  @media screen and (max-width: 420px){
    padding: 0 10px 40px;
    .col-md-4{
      padding: 0;
      padding-bottom: 20px;
      margin: 20px 0;
      border-bottom: 1px solid @lsv_grey_very_dark;
    }
  }
}
.lsv-news, .lsv-contacts, .lsv-press-releases{
  background-color: @lsv_slide_bg;
}
.product-card-mini{
  padding: 0 6px;
  min-height: 660px;
  transition: all 500ms linear;
  img{
    display: block;
    width: 100%;
  }
  &:after{
      content: "Читать далее";
      width: 100%;
      display: none;
      padding: 45% 25%;
      position: absolute;
      top: 0;
      color: white;
      background-image: url(../img/svg/dairy-products-show-production.svg);
      background-repeat: no-repeat;
      background-position: 60% center;
      @media screen and (max-width: 1100px) and (min-width: 993px){
        background-position: 65% center;
      }
      @media screen and (max-width: 1600px) and (min-width: 1280px){
        background-position: 60% center;
        padding: 49% 30%;
      }
      @media screen and (min-width: 1600px){
        background-position: 55% center;
        padding: 49% 32%;
      }
  }
  &:hover:after{
      
      display: block;
  }
  // .lsv-new_hover-div{
  //   width: 100%;
  //   padding-top: 100%;
  //   position: absolute;
  //   background-color: black;
  // }
  span{
    margin: 20px 0;
    display: block;
  }
  h2{
    text-transform: uppercase;
    text-decoration: underline;
    font-size: 18px;
    font-weight: 900;
    margin-bottom: 24px;
  }
  p{
    font-size: 15px;
  }
  .img-bg-wraper{
    background-color: black;
  }
  &:hover img{
    // -webkit-filter: brightness(50%);
    // filter: brightness(50%);
    opacity: 0.5;
  }
  @media screen and (max-width: 768px){
    min-height: initial;
    margin-bottom: 60px;
  }
  @media screen and (min-width: 1440px){
    min-height: 700px;
    margin-bottom: 60px;
  }
  @media screen and (min-width: 1900px){
    min-height: 800px;
    margin-bottom: 60px;
  }
}
.arrow{
  background-image: url('../img/svg/arrow.svg');
  background-repeat: none;
  background-position: right;
}
.lsv-top-displace{
  padding-top: @lsv_menu_height;
}
.lsv-contacts__main-contacts{
  padding: 40px;
}
.lsv-h1, .lsv-h2, .lsv-h3{
  text-transform: uppercase;
  font-weight: 900;
}
.lsv-h1--dark-grey{
  color: @lsv_grey_very_dark;
}
.lsv-h2{
  color: @lsv_green;
  font-size: 18px;
}
.lsv-h2--grey-underline{
  color: @lsv_grey_very_dark;
  text-decoration: underline;
  font-size: 18px;
}
.lsv-h3{
  color: @lsv_grey_very_dark;
  font-size: 15px;
}
.form-control{
  border: 0;
  border-radius: 0;
  &:focus{
    box-shadow: none;
    outline: 0;
    border: 1px solid @lsv_green;
  }
}
.lsv-faq{
  background-color: @lsv_slide_bg;
}

.navbar-fixed-top{
  border: 0;
}
.lsv-article{
  background-color: @lsv_slide_bg;
}

.lsv-article__img{
  width: 100%;
}
.lsv-article__link-back{
  margin-top: 21px;
  margin-bottom: 24px;
  a{
    color: @lsv_grey_very_dark;
    text-decoration: underline;
    &:hover{
      color: @lsv_green;
    }
  }
}
.lsv-article__text{
  margin-bottom: 70px;
}
.lsv-release{
  padding: 0 63px 0 0;
  margin-bottom: 20px;
  margin-top: 20px;
  min-height: 170px;
}
.lsv-press_about{
  padding: 0 63px 0 0;
  margin-bottom: 20px;
  margin-top: 20px;
  min-height: 200px;
  h2{
    margin: 15px 0;
  }
}

.lsv-release__header{
  display: block;
  margin-bottom: 7px;
  &:before{
    display: inline-block;
    width: 20px;
    height: 15px;
  }
}
.lsv-release__header--press{
  color: @lsv_press_release_color;
  border-color: @lsv_press_release_color;
}
.lsv-release__header--post{
  color: @lsv_post_release_color;
  border-color: @lsv_post_release_color;
}
.lsv-release__header--comment{
  color: @lsv_comment_color;
  border-color: @lsv_comment_color;
}
.lsv-release__header-icon{
  position: relative;
  margin-right: 5px;
  top: -1px;
  padding: 3px;
  border-radius: 2px;
  border-width: 1px;
  border-style: solid;
  font-size: 12px;
}
.lsv-release__source{
  a{
    color: @lsv_green;
    text-decoration: underline;
    &:hover{
      color: @lsv_green_active;
      text-decoration: none;
    }
  }
}
.lsv-products-section{
  background-color: @lsv_slide_bg;
}
.lsv-products{
  padding: 60px;
  margin: 0;
  background-color: @lsv_slide_bg;
}
.lsv-category-milk{
  color: #119BD7;
  margin-bottom: -25px!important;
}
.lsv-category-cheese{
  color: #f9b316;
}
.lsv-category-beef{
  color: #b92727;
  margin-bottom: -25px!important;
}
.lsv-category-poultry{
  color: #F19A67;
}
.lsv-category-honey{
  color: #FFCA59;
}
.lsv-category-cooking{
  color: #ED145B;
}
.lsv-category-plant-food{
  color: #851e62;
  margin-bottom: -25px!important;
}
.lsv-category-approved{
  color: @lsv_green;
}
.category-card__name{
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 900;
  bottom: 0;
  position: absolute;
  right: 0;
  left: 0;
  margin: auto;
}
.lsv-product-logo{
  height: 150px;
  position: absolute;
  margin: auto;
  right: 0;
  left: 0;
  clear: both;
  &:hover {
    animation: scale-circle-milk 1300ms ease infinite;
    transform-origin: 50% 50%;      
  }
  &.inactive:hover{
    animation: none;
  }
}
@-webkit-keyframes scale-circle-milk{
  0% { transform: scale(1); }
  50% { transform: scale(0.8); }
  100% { transform: scale(1); }
}
@keyframes scale-circle-milk{
  0% { transform: scale(1); }
  50% { transform: scale(0.8); }
  100% { transform: scale(1); }
}
.lsv-products__category-cadr{
  min-height: 200px;
  margin: 50px 0;
}

.lsv-ferm{
  background-color: #65A566;
  // height: 100%;
  padding-top: @lsv_menu_height;
  overflow-x: scroll;
  svg{
  }
}
.lsv-ferm-svg{
  top: @lsv_menu_height;
  height: 90%;
  position: absolute;
  margin: auto;
  right: 0;
  left: 0;
}
.fullslide_img_text{
  top: @lsv_menu_height;
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  background-origin: border-box;
  background-position: center;
  background-clip: content-box;
  height: 100%;
  width: 100%;
  h1{
    font-size: 40px;
    font-weight: 900;
    position: relative;
    top: @lsv_menu_height + 50px;
    left: 100px;
    color: white;
  }
}
.lsv-grey-very-dark{
  color: @lsv_grey_very_dark;
}
.lsv-history__img{
  top: @lsv_menu_height;
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  background-origin: border-box;
  background-position: center;
  background-clip: content-box;
  padding: 50px 10px 105px 40px;
  width: 50%;
  height: 100%;
  @media screen and(max-width:420px){
    position: initial;
    padding: 0;
    height: 280px;
    width: 100%;
  }
  @media screen and(max-width:360px){
    position: initial;
    padding: 0;
    height: 280px;
    width: 100%;
  }
}
.lsv-history__img--big{
  padding-right: 100px;
  width: 100%;
  @media screen and(max-width:420px){
    position: initial;
    padding: 0;
    height: 280px;
    width: 100%;
  }
}
#lsv-history__start{
  // background-image: url(../img/images/2004.jpg);
  &.fullslide_img_text>h1{
    @media screen and(max-width:420px){
      left: 0;
      padding-left: 25px;
    }
    @media screen and(max-width:360px){
      left: 0;
      font-size: 36px;
      padding-left: 15px;
    }
  }
}
#lsv-history__2004{
  // background-image: url(../img/images/2004.jpg);
}
#lsv-history__2005{
  // background-image: url(../img/images/2004.jpg);
}
#lsv-history__2008{
  // background-image: url(../img/images/2008.jpg);
}
#lsv-history__2012{
  // background-image: url(../img/images/IMG_6611.jpg);
}
#lsv-history__2013{
  // background-image: url(../img/images/2013.jpg);
}
#lsv-history__2014{
  // background-image: url(../img/images/2014.jpg);
}
#lsv-history__2015{
  // background-image: url(../img/images/2015.jpg);
}
.lsv-history__discription{
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 30%;
  margin-bottom: 35px;
  margin-left: 30px;
  &>.lsv-h1{
    font-size: 30px;
  }
  &--top{
    top: 0;
    margin-top: 90px;
  }
  &--top-left{
  top: 0;
  left: 0;
  margin-top: 120px;
  margin-left: 120px;
  }
  &--middle{
    top: 0;
    margin-top: 150px;
    h1{
      @media screen and (max-width: 1024px) and (min-width: 420px){
        top: 0;
        margin-top: -45px;
      }
    }

    &>p:last-child{
      position: absolute;
      bottom: 0;
      margin-bottom: 10px;
      @media screen and (max-width:420px){
        display: none;
      }
    }
    &>p:nth-child(2){
      @media screen and (max-width: 1024px) and (min-width: 420px){
        position: absolute;
        top: 0;
      }
      
    }
  }
  &--middle-2013-2{
    position: absolute;
    top: 45%;
    &>p:last-child{
      margin-top: 50px;
    }
  }
  @media screen and (max-width:420px){
    position: initial;
    width: 100%;
    left: 0;
    margin: 0;
    padding: 0 15px;
    top: 55%;
  }
  @media screen and (max-width:360px){
    position: initial;
    width: 100%;
    left: 0;
    margin: 0;
    padding: 0 15px;
    top: 50%;
  }
}
.lsv-slides--bg-grey{
  .section{
    background-color: @lsv_slide_bg;
  }
  .lsv-footer{
    background-color: @lsv_footer_color;
  }
}
.lsv-white{
  color: white;
  @media screen and (max-width:420px){
    color: @lsv_grey_very_dark;
  }
}
.lsv-history__timeline{
  position: fixed;
  height: 100%;
  width: 100px;
  right: 1px;
  top: 0;
  z-index: 1021;
  transition: all 500ms ease;
  opacity: 0;
  @media screen and (max-width: 420px){
    display: none;
  }
}
.timeline__line{
  height: 70%;
  width: 1px;
  border-left: 1px solid @lsv_grey_very_dark;
  position: absolute;
  margin: auto;
  top: @lsv_menu_height;
  bottom: 0;
  left: 70px;
  z-index: 2000;
}
.timeline__years{
  position: absolute;
  width:100px;
  left: -47px;
  
  span{
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 100%;
    border: 1px solid @lsv_grey_very_dark;
    background-color: @lsv_slide_bg;
    position: relative;
    top: 2px;
    transition: all 1000ms ease;
    transform-origin: 50% 50%;
    margin-left: 5px;
  }

}
.timeline--active{
  span{
    background-color: @lsv_grey_very_dark;
    transform:scale(1.3,1.3);
    transition-duration: 500ms;
   }
}
.lsv-404__message{
  width: 60%;
  margin: auto;
  left: 0;
  right: 0;
  // position: absolute;
  a{
    position: absolute;
    left:0;
    right: 0;
    margin: auto;
  }
}
.lsv-404__erorr-list{
  padding-left: 15px;
}
.lsv-404{
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: @lsv_slide_bg;
}
.lsv-slide-bgc{
  background-color: @lsv_slide_bg;
}
.lsv-vacancies__header{
  position: absolute;
  top: @lsv_menu_height + 46px - 20px;
  left: 38px;
}
.flex-container{
  display:inline-flex;
  justify-content: center;
}
.lsv-vacancies{
  .owl-carousel{
    margin-top: 150px;
  }
}
.lsv-vacancies__menu{
  width: 100%;
  // display:inline-flex;
  // justify-content: space-around;
  // flex-wrap: wrap;
  text-align: center;
  a{
    display: inline-block;
    height: 85px;
    @media screen and (max-width: 678px){
      width: 100%;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      justify-content: center;
    }
  }
  div{
    display: inline-block;
  }
  .lsv-h1{
    color: @lsv_grey_very_dark;
    padding: 0 33%;
    cursor: pointer;
    transition: all 300ms;
    &:hover{
      color: @lsv_green;
    }
    @media screen and (max-width: 992px){
      padding: 0 40px;
    }
    @media screen and (max-width: 480px){
      padding: 0 20px;
      font-size: 20px;
    }
  }
}
#lsv-vacancies-menu-btn{
  @media screen and (max-width: 480px){
    display: none;
  }
}
.lsv-vacancies__category-img{
  height: 80px;
  fill: @lsv_grey_very_dark;
  // transition: all 700ms ease;
  &:hover{
    fill: @lsv_green;
  }
}
.lsv-vacancies__career-btn{
  position: absolute;
  top: 80%;
  margin-top: -100px;
  width: 100%;
  display:inline-flex;
  justify-content: center;
  span{
    font-size: 18px;
  }
}
.lsv-vacancies__response-btn{
  position: absolute;
  bottom: 0;
  right: 0;
  span{
    font-size: 18px;
  }
  button{
    margin: 0 42px 27px 0;
  }
  @media screen and (max-width: 768px){
    
  }
  @media screen and (max-width: 480px){
    // right: 0;
    // left: 0;
    // bottom: 30px;
    // margin: auto;
    // width: 178px;
    // button{
    //   margin: 0;
    //   margin-bottom: 10px;
    // }
    display: none;
  }
}
.lsv-vacancies__office-header{
  position: absolute;
  left: 105px;
  top: @lsv_menu_height + 46px - 20px;
  button{
    position: absolute;
    bottom: 0;
    right: 0;
  }
  @media screen and (max-width: 420px){
    position: absolute;
    top: 66px;
    left: 0;
  }
}
.lsv-vacancies__vacancy-item{
  height: 80%;
  div{
    padding: 0 10%;
    padding-right: 30px;
  }
  ul{
    list-style-type: none;
    padding-left: 0px;
    @media screen and (max-width:420px){
      margin-bottom: 0;
    }
  }
  div.col-md-6{
    @media screen and (max-width:992px){
      padding: 0 60px;
    }
    @media screen and (max-width:420px){
      padding: 0 40px;
    }
  }

}

#owl-vacancy-office-slider,#owl-vacancy-shops-slider, #owl-vacancy-ferm-slider,#owl-vacancy-production-slider, #owl-team-office-slider, #owl-team-ferm-slider, #owl-team-shops-slider, #owl-team-production-slider {
  .owl-pagination{
    margin-top: 40px;
  }
  .owl-buttons{
    // position: absolute;
    top: 0;
    // height: 100%;
    // width: 100%;
    div{
      margin: 0;
      height: 100%;
      color: transparent;
      background-color: transparent;
      background-position:center;
      background-repeat: no-repeat;
      opacity: 1;
      &:hover{
        opacity: 0.7;
      }
      @media screen and (max-width:480px){
        background-position: 35% 50px;
      }
    }
    .owl-prev{
      // float: left;
      background-image: url('../img/svg/slider-arrow-left.svg');
      position: absolute;
      left: 0;
      top: 0;
    }
    .owl-next{
      // float: right;
      background-image: url('../img/svg/slider-arrow-right.svg');
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}
#owl-vacancy-office-slider,#owl-vacancy-shops-slider, #owl-vacancy-ferm-slider,#owl-vacancy-production-slider{
  
  @media screen and (min-width:480px) and (max-width:768px){
    margin-top: 80px;
  }
  @media screen and (max-width:480px){
    margin-top: 160px;
    .owl-pagination{
      margin-top: 60px;
    }
  }
}
.lsv-vacancies__form{
  width: 100%;
  max-width: 1024px;
  margin: auto;
  .form-group{
    padding: 40px;
    &:first-child{
      padding-right: 10px;
    }
    &:last-child{
      padding-left: 10px;
    }
  }
  .form-control{
    margin: 24px 0px;
    height: 48px;
    background-color: #fff;
    @media screen and (max-width: 768px){
      height: 36px;
      margin: 16px 0px;
    }
  }
  textarea.form-control{
    height: 264px;
    @media screen and (max-width: 768px){
      height: 100px;
    }
  }
  .help-block{
    float: left;
    width: 40%;
    margin-top: 0;
    @media screen and (min-width: 480px) and (max-width: 880px){
      width: 100%;
    }
  }
  div.col-md-6{
    @media screen and (max-width: 768px){
      padding: 0 30px;
    }
  }
  input.filestyle{
    width: 1px;
  }
}
@media screen and (max-width: 768px){
  .lsv-vacancies__form div.col-md-6:first-child,.lsv-vacancies__form div.col-md-6:last-child{
    padding: 0 30px;
  }
}
.lsv-btn__input-file{
  height: 48px;
  background-color: @lsv_grey_very_dark;
  padding-left: 50px;
  padding-right: 50px;
  .buttonText{
    font-size: 18px;
    position: relative;
    top: 12%;
  }
}
label.lsv-btn__input-file{
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}
.lsv-form__btn-submit--vacancy{
  margin: 0;
  padding: 11px 30px;
  float:right;
  width: 223px;
  span{
    font-size: 18px;
  }
  @media screen and (max-width: 880px){
    width: 100%;
  }
}

#lsv-vacanceis__career-img1,#lsv-vacanceis__career-img2,#lsv-vacanceis__career-img3{
  // background-image: url(http://placehold.it/600x700);
}

.lsv-slider{
  .owl-buttons{
    position: absolute;
    top: 50%;
    div.owl-prev, div.owl-next{
      opacity: 1;
      height: 35px;
      width: 35px;
      float:left;
      clear:both;
      color:transparent;
      background-color: transparent;
      background-image: url(../img/svg/slider-arrow-circle.svg);
      background-repeat: no-repeat;
      background-position: center;
      transition: all 500ms ease;
      &:hover{
        opacity: 0.7;
      }
    }
    div.owl-next{
      transform-origin: 50% 50%;
      transform: rotate(180deg);
    }
  }
  .owl-controls{
    margin-top: 0;
  }
}
.full-slide{
  margin-top: @lsv_menu_height;
  height: 800px;
  // background-image: url(http://placehold.it/800x800);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
#owl-shops-slider{
  // #slide_1{background-image: url(http://placehold.it/800x800);}
  // #slide_2{background-image: url(http://placehold.it/800x600);}
  // #slide_3{background-image: url(http://placehold.it/500x800);}
  // #slide_4{background-image: url(http://placehold.it/500x500);}
  .owl-buttons{
    right: 15px;
  }
}
.lsv-vacansies__career-description{
  padding-top: @lsv_menu_height + 100px;
  padding-left: 20px;
  padding-right: 50px;
  height: 100%;
  span{
    font-weight: 400;
    font-size: 15px;
    text-transform: none;
  }
  h3.lsv-h3{
    padding-bottom: 20px;
  }
}
.lsv-return-btn{
  display: none;
  width: 35px;
  height: 35px;
  position: fixed;
  top: @lsv_menu_height + 25px;
  right: 20px;
  background-image: url(../img/svg/slider-arrow-circle.svg);
  background-repeat: no-repeat;
  background-position: center;
  transform-origin: 50% 50%;
  transform: rotate(90deg);
  z-index: 1030;
  transition: all 500ms ease;
  &:hover{
    opacity: 0.7;
  }
  @media screen and (max-width:480px){
    display: none;
  }
  &.active{
    display: block;
  }
}
@media screen and (max-width: 1024px){
  .lsv-team{
    #lsv-team__img-start, .fullwidth-img{
      position: initial;
      height: 60vh;
      min-height: 400px;
    }
    .lsv-team__text-start{
      position: initial;
    }
    .lsv-team__owner{
      position: initial;
    }
    .lsv-team__text-owner{
      position: initial;
    }
    .fullwidth-img--onethird{
      position: relative;
    }
  }  
}


#lsv-team__img-start{
  width: 100%;
  height: ~"calc(60% - 62px)";
  margin-top: @lsv_menu_height;
  // background-image: url(../img/images/team_start.jpg);
  
}
#lsv-team__img-owner-1{
  // background-image: url(../img/images/beglov.jpg);
}
#lsv-team__img-owner-2 {
  // background-image: url(../img/images/mauer.jpg);
}

#lsv-team__img-start{
  @media screen and (max-width: 768px){
    width: 100%;
    height: 45%;
    margin-top: @lsv_menu_height;
  }
}
.lsv-team__owner{
  width: 100%;
  height: 50%;
  position: absolute;
  top: @lsv_menu_height;
  &:first-child{
    height: 50%;
  }
  &:last-child{
    top: 50%;
  }
}
.lsv-team__text-start{
  position: absolute;
  top: 60%;
  width: 100%;
  padding: 20px 7% 0 7%;
  p{
    margin: 20px 0;
  }
  @media screen and (max-width: 768px){
    top: @lsv_menu_height;
    padding: 10px 40px;
    width: 100%;
    left: 0;
  }
  @media screen and (max-width: 420px){
    width: 100%;
    height: 100%;
    top: 45%;
    left: 0;
    padding: 0 20px;
  }
}
.lsv-team__text-owner{
  position: absolute;
  left: 34%;
  padding: 40px 80px;
  top: 0;
  .lsv-h2{
    color: @lsv_grey_very_dark;
    margin-bottom: 30px;
  }
  @media screen and (max-width:420px){
    padding: 20px 10px;
    left: 0;
    width: 100%;
    top: 50%;
  }
  @media screen and (max-width:768px){
    padding: 30px 40px;
    left: 0;
  }

  @media screen and (max-width:1200px){
    padding: 20px 20px 20px 40px;
  }

}
.fullwidth-img{
  position: absolute;
  top: 0;
  width: 50%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.fullwidth-img--onethird{
  height: 100%;
  width: 34%;
  @media screen and (max-width: 420px){
    width: 100%;
  }
  @media screen and (max-width: 1024px){
    width: 100%;
  }
  img{
    position: absolute;
    right: -32px;
    top: 45%;
    @media screen and (max-width: 1024px){
      top: initial;
      bottom: -32px;
      right: 0;
      left: 0;
      margin: auto;
    }
  }
}
.lsv-team__employee-img{
  margin: auto;
  width: 140px;
  height: 140px;
  // background-image: url(../img/images/team_products_1.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 50%;
  img{
    position: relative;
    left: -5px;
    top: 140px - 64px + 5px;
    width: 64px;
    height: 64px;
  }
}
.lsv-team__employee-post{
  max-width: 260px;
  margin: auto;
  min-height: 55px;
}
#team-production-1{
  // background-image: url(../img/images/team_products_1.jpg);
}
#team-production-2{
  // background-image: url(../img/images/team_products_2.jpg);
}
#team-production-3{
  // background-image: url(../img/images/team_products_3.jpg);
}
#team-production-4{
  // background-image: url(../img/images/team_products_4.jpg);
}
#team-production-5{
  // background-image: url(../img/images/team_products_5.jpg);
}

.lsv-team__employee-comment{
  padding: 0 44px;
  @media screen and (max-width: 420px){
    display: none;
  }
}
.lsv-team__office-header{
  margin-top: @lsv_menu_height;
  margin-bottom: 20px;
}
.lsv-about__start{
  position: absolute;
  top: 0;
  padding-top: @lsv_menu_height;
  width: 100%;
  height: 100%;
  // background-image: url(../img/images/banner.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  .lsv-h1{
    font-size: 30px;
    color: white;
    position: relative;
    margin: auto;
    top: 50%;
  }
}
.lsv-about__about-block{
  padding: 0 120px;
  padding-top: 75px;
  p,h2,li{
    text-align: left;
    color: @lsv_grey_very_dark;
    margin-bottom: 20px;
  }
  ul{
    padding: 0;
    list-style-type: none;
  }
  @media screen and (max-width: 768px){
    padding: 20px 30px 0;
  }
  @media screen and (max-width: 480px){
    padding: 20px 30px;
  }
}
.lsv-shops__header{
  padding: 20px 40px 10px;
  position: absolute;
  width: 100%;
  top: @lsv_menu_height;
  background-color: @lsv_slide_bg;
  z-index: 2000;
  text-align: center;
  @media screen and (max-width: 420px){
    padding: 20px 20px 10px;
  }
}
.lsv-shops__filter-btn{
  
  display: inline;
  float: right;
  margin-top: -20px;
  &:hover>*{
    cursor: pointer;
  }
  label{
    display: inline;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 400;
    margin-top: -29px;

    @media screen and (max-width: 420px){
      display: inline-block;
      width: 90px;
      margin-top: -9px;
    }
  }
  svg{
    display: inline;
    float: right;
    margin-top: -10px;
    margin-left: 20px;
  }
}
#lsv-shops__map{
  z-index: 2000;
  position: fixed!important;
  top: @lsv_menu_height + 60px;
  height: 80%;
  width: 50%;
  @media screen and (max-width: 480px){
    width: 100%;
  }
}
.lsv-shops__list{
  position: relative;
  top: @lsv_menu_height + 60px;

  height: 100%;
  // overflow-y: scroll;
  padding-left: 33px;
  @media screen and (max-width: 480px){
    display: none;
  }
}
.lsv-shops__list-item{
  cursor: pointer;
  margin-bottom: 48px;
  transition: all 500ms ease;
  .lsv-h3{
    color: @lsv_green;
    margin-bottom: 5px;
  }
  p{
    margin: 0;
  }
  &.active{
    border-left: 3px solid @lsv_green;
    padding-left: 20px;
  }
}
.lsv-shops__map-info{
  position: fixed;
  bottom: 0;
  height: 13%;
  width: 50%;
  background-color: @lsv_green;
  color: white;
  padding: 20px 20px 20px 30px;
  // display: flex;
  display: table;
  justify-content: center;
  flex-direction: column;
  z-index: 2001;
  .lsv-h3{
    color: white;
    margin: 0;
    margin-bottom: 5px;
  }
  p{
    margin: 0;
  }
  @media screen and (max-width: 1024px){
    height: 16%;
    padding: 20px 20px 20px 20px;
  }
  @media screen and (max-width: 540px){
    height: 16%;
    padding: 10px;
  }
  @media screen and (max-width: 480px){
    width: 100%;
  }
  @media screen and (max-width: 420px){
    height: 20%;
  }
}
.lsv-shops__filter{
  background-color: @lsv_slide_bg;
  position: fixed;
  margin-top: -1px;
  top: @lsv_menu_height + 60px;
  left: 100%;
  height: 90%;
  width: 50%;
  z-index: 2002;
  transition: all 800ms cubic-bezier(0.31, 0.79, 0, 0.99);
  padding: 30px;
  & > div{
    height: 50%;
    width: 100%;
    &:first-child{
      border-bottom: 2px solid @lsv_grey_light;
      @media screen and (max-width: 480px){
        border: 0;
        height: 100%;
      }
    }
    @media screen and (max-width: 960px){
      height: 35%;
    }
    @media screen and (max-width: 768px){
      height: 30%;
    }
    
  }
  @media screen and (max-width: 960px){
    width: 100%;
    padding: 0 30px;
  }
}
.lsv-shops__filter-menu{
  .lsv-form__btn-submit--vacancy{
    display: none;
    @media screen and (max-width: 480px){
      display: block;
      width: 100%;
    }
  }
}
.lsv-shops__filter--active{
  left:50%;
  @media screen and (max-width: 960px){
    left: 0;
  }
}
.lsv-shops__filter-form{
  #filterAppy{
    position: absolute;
    width: 100%;
    text-align: center;
    @media screen and (max-width: 480px){
      display: none;
    }
  }
  .form-group{
    width: 50%;
    display: inline-block;
    position: relative;
    &:last-child{
      width: 40%;
      top: -2px;
      left: 30px;
      position: relative;
    }
  }
  .form-control{
    padding: 0px 12px;
  }
  label{
    text-transform: uppercase;
    font-weight: 400;
  }
  select{
    margin-bottom: 20px;
    height: 26px;
    @media screen and (max-width: 480px){
      height: 42px;
    }
  }
  button{
    padding: 6px 30px;
    width: 100%;
  }
  @media screen and (max-width: 998px){
    .form-group{
    width: 50%;
    display: inline-block;
    }
  }
  @media screen and (max-width: 480px){
    .form-group{
      width: 100%;
      &:last-child{
        position: absolute;
        top: 35%;
        left: 27px;
        width: 80%;
      }
    }
  }
  @media screen and (max-width: 360px){
    .form-group{
      width: 100%;
      &:last-child{
        position: absolute;
        top: 42%;
        left: 27px;
        width: 80%;
      }
    }
  }
  @media screen and (max-width: 480px){
    height: 70%;
  }
}
.lsv-radio {
  vertical-align: top;
  width: 26px;
  height: 26px;
  margin: 0 3px 0 0;
}
.lsv-radio + label {
  cursor: pointer;
  text-transform: none;
  margin-bottom: 15px;
  display: block;
  white-space: nowrap;
}
.lsv-radio:not(checked) {
  position: absolute;
  opacity: 0;
}
.lsv-radio:not(checked) + label {
  position: relative;
  padding: 0 0 0 35px;
}
.lsv-radio:not(checked) + label:before {
  content: '';
  position: absolute;
  top: -2px;
  left: 0;
  width: 26px;
  height: 26px;
  // border: 1px solid black;
  border-radius: 50%;
  background: #FFF;
}
.lsv-radio:not(checked) + label:after {
  content: '';
  position: absolute;
  top: 6px;
  left: 8px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: @lsv_green;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.5);
  opacity: 0;
  transition: all .2s;
}
.lsv-radio:checked + label:after {
  opacity: 1;
}
.lsv-radio:focus + label:before {
  box-shadow: none;
}

input[type='reset'] {
  padding: 12px 15px;
  border: none;
  border-radius: 5px;
  background: #CDD1DA;
  color: #FFF;
  cursor: pointer;
  font-size: 15px;
}
input[type='reset']:active {
  background: #BDC0CC;
}
input[type='reset']:focus {
  outline: none;
  box-shadow: 0 0 0 3px @lsv_green_active;
}
.lsv-shops__create-own-shop{
  label{
    margin: 28px 0;
    width: 100%;
  }
  input{
    height: 26px;
    margin-bottom: 20px;
  }
  button{
    margin-top: 10px;
    max-width: 223px;
    float: none;
  }
  textarea{
    width: 100%;
    height: 25px*3+20px*2+ 3;
    resize: none;
    padding: 3px 7px;
    border: 0;
    border-radius: 0;
  }
  .lsv-shops__filter-form{
    position: relative;
    .form-group:nth-child(2){
      text-align: center;
      float: left;
    }
    .form-group:last-child{
      width: 45%;
      float: right;
      margin-top: 3px;
      margin-right: 20px;
      // top: 0px;
      // left: 55%;
      // position: absolute;
    }
  }
  @media screen and (max-width: 480px){
    display: none;
  }
}
#lsv-shops__filter-btn.active{
  svg{
    circle{
      fill: @lsv_green;
    }
  }
}

.lsv-diary-products__start{
  position: absolute;
  top: 0;
  padding-top: @lsv_menu_height;
  width: 100%;
  height: 100%;
  // background-image: url(../img/images/milk.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  p{
    position: absolute;
    top: 25%;
    left: @lsv_menu_height*2;
    width: 40%;
    color: white;
  }
  &.cheese{
    // background-image: url(../img/images/cheese.jpg);
  }
  &.cooking{
    // background-image: url(../img/images/cooking.jpg);
  }
  &.cheese{
    // background-image: url(../img/images/cheese.jpg);
  }
}
.lsv-dairy-products{
  width: 100%;
  height: 100%;
  // padding-top: @padding_top_const;
}
.lsv-dairy-products__menu-btn{
  position: absolute;
  top: @lsv_menu_height;
  margin-left: 15px;
  margin-top: 8px;
  cursor: pointer;
  z-index: 1000;
  label{
    text-transform: none;
    text-decoration: underline;
    color: white;
    font-weight: 400;
    position: relative;
    top: 12px;
    left: 5px;
    cursor: pointer;
  }
}
.lsv-dairy-products__next-animation{
  display: none;
  position: absolute;
  // top: @lsv_menu_height;
  margin-left: 15px;
  margin-top: 8px;
  cursor: pointer;
  z-index: 1000;
  label{
    text-transform: none;
    text-decoration: underline;
    color: white;
    font-weight: 400;
    position: relative;
    top: 12px;
    left: 5px;
    cursor: pointer;
  }
}
.lsv-dairy-products__menu-btn{
  span.bar{
    display: block;
    width: 100%;
    height: 3px;
    margin-bottom: 5px;
    background-color: #fff;
  }
  @media screen and (max-width: 480px){
    width: @lsv_menu_height;
    height: @lsv_menu_height;
    background-color: #2c2d2d;
    margin: 0;
    padding: 21px 18px;
  }
  img{
    @media screen and (max-width: 480px){
      display: none;
    }
  }
  label{
    @media screen and (max-width: 480px){
      display: none;
    }
  }
}

.lsv-dairy-products__menu{
  position: relative;
  // top: @lsv_menu_height;
  padding-top: @lsv_menu_height;
  background-color: white;
  color: @lsv_grey_very_dark;
  z-index: 1031;
  left: -280px;
  transition: all 800ms cubic-bezier(0.31, 0.79, 0, 0.99);
  ul{
    list-style: none;
    padding: 0;
    .not-visible:hover{
      background-color: #2c2d2d;
    }
  }
  li{
    //height: 48px;
    min-height: 48px;
    padding: 15px 10px 15px 30px;
    cursor: pointer;
    &:hover{
      background-color: @lsv_slide_bg;
    }
    &:nth-child(2){
      display: none;
    }
    // &:first-child{
    //   position: fixed;
    //   top: 0 ; //@lsv_menu_height;
    //   // margin-top: @lsv_menu_height;
    //   z-index: 1041;
    //   background-color: #2c2d2d;
    //   text-align: right;
    //   position: fixed;
    //   z-index: 1041;
    //   width: 280px;
    //   background-color: #2c2d2d;
    //   height: 48px;
    //   transition: all 800ms cubic-bezier(0.31, 0.79, 0, 0.99);
    //   &.active{
    //     left: 0;
    //   }
    //   @media screen and (max-width: 480px){
    //     width: 100%;
    //   }
    // }

  }
  &.active{
    left: 0;
    z-index: 1035;
  }
  #lsv-dairy-products__menu-close-btn{
    // left: -280px;
    // top: @lsv_menu_height;
    // &.active{
    //   left: 0;
    // }
    text-align: right;
    img{
      transition: all 700ms cubic-bezier(.41,2.15,.33,.72);
      margin: -6px 25px 0 0;

    }
    &:hover>img{
      transform:rotate(90deg);
    }
    // @media screen and (max-width: 480px){
    //   left: -100%;
    // }
  }
  // @media screen and (max-width: 480px){
  //   left: -100%;
  // }
  @media screen and (max-width: 480px){
    left: -100%;
  }
}
.animation-container{
  width: 50%;
  height: 72vh;
  position: absolute;
  left: 25%;
  top: 15%;
  &.start{
    height: 40%;
    top: 30%;
    @media screen and (max-width: 360px){
      top: 35%;
    }
  }
  @media screen and (max-width: 768px){

  }
  @media screen and (max-width: 480px){
    left: 0%;
    top: @lsv_menu_height*2+ 16px;
    width: 100%;
    height: 75%;
  }
}
#animation-canvas{
  height: 100%;
  width: 100%;
}
.lsv-dairy-products__next-animation{
  bottom: 0;
  // top: initial;
  left: 33%;
  img{
    margin-left: 10px;
  }
}
.lsv-production__text-block{
  padding-right: 20px;
  position: absolute;
  width: 25%;
  left: 75%;
  top: 33%;
  color: white;
  .lsv-h2{
    color: white;
  }
}

.lsv-dairy-products__goodies-btns{
  display: none;
  position: absolute;
  top: @lsv_menu_height+24px;
  right: 31px;
  img{
    display: inline-block;
    margin-left: 10px;
    cursor: pointer;
  }
  @media screen and (max-width: 480px){
    top: @lsv_menu_height+12px;
    right: 12px;
  }
}
#vk_like{
  position: absolute;
  bottom: 0;
  right: 0;
}
.lsv-dairy-products__product-title{
  position: absolute;
  top: @lsv_menu_height;
  left: 0;
  right: 0;
  margin: auto;
  h2.lsv-h2{
    color: white;
    font-weight: 400;
  }
  @media screen and (max-width: 480px){
    top: @lsv_menu_height*2-15px;
    padding: 0 20px;
  }
  @media screen and (max-width: 360px){
    top: 121px;
    padding: 0 20px;
  }
}
.lsv-dairy-products__tooltip{
  position: absolute;
  width: 30%;
  height: 60%;
  right: 0;
  top: @lsv_menu_height + 90px;
  margin-right: 30px;
  background: white;
  overflow-y: auto;
  border-radius: 20px;
  z-index: 1033;
  padding: 30px 25px;
  opacity: 0;
  display: none;
  transition: all 800ms cubic-bezier(0.31, 0.79, 0, 0.99);
  img{
    position: absolute;
    top: -15px;
    right: 21px;
    z-index: 1029;
  }
  &.active{
    opacity: 1;
    display: block;
  }
  @media screen and (max-width: 768px){
    width: 50%;
    margin-right: 5%;
    height: 70%;
    top: @lsv_menu_height + 80px;
  }
  @media screen and (max-width: 480px){
    width: 90%;
    margin-right: 5%;
    height: 70%;
    top: @lsv_menu_height + 80px;
  }

}
#lsv-dairy-products__tooltip-info{
  img{
    right: 82px;
  }
}
.lsv-shops__text-block{
  padding: @lsv_menu_height+80px 40px;
  @media screen and (max-width: 480px){
    padding: @lsv_menu_height+5px 40px 0;
  }
}
.full-img{
  width: 50%;
  height: 100%;
  position: absolute;
  // background-image: url(http://placehold.it/1920x1000);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
#owl-vacancy-career-slider{
  .owl-pagination{
    margin-top: 40px;
  }
  .full-img{
    @media screen and (max-width:768px){
      width: 100%;
      height: 40%;
      margin-top: @lsv_menu_height;
    }
  }
  .owl-buttons{
    @media screen and (max-width:420px){
      top: 17%;
    }
    @media screen and (max-width:768px){
      top: 46%;
      right: 0;
    }
  }
  .lsv-vacansies__career-description{
    @media screen and (max-width:768px){
      width: 100%;
      padding-top:320px;
    }
    @media screen and (max-width:480px){
      width: 100%;
      padding-top:150%;
    }
    @media screen and (max-width:360px){
      width: 100%;
      padding-top:160%;
    }
  }
}
.lsv-mobile-menu{
  background-color: @lsv_green_active;
  width: 320px;
  position: fixed;
  z-index: 2002;
  top: @lsv_menu_height - 510px-20px;
  color: white;
  text-transform: uppercase;
  transition: all 500ms cubic-bezier(.215,.61,.355,1);
  overflow-y: scroll;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  &::-webkit-scrollbar { width: 0; }
  &.active{
    top: @lsv_menu_height;
    height: 100%;
  }
  ul{
    list-style: none;
    padding: 0;
    margin: 0;
  }
  a:hover{
    text-decoration: none;
  }
  a:first-child>li{
    padding-top: 16px;
  }
  a:last-child>li{
    padding-bottom: 16px;
  }
  li{
    padding: 8px 0 8px 16px;
    border-bottom: 1px solid #56ad3c;
    border-right: 1px solid #56ad3c;
    
    &:hover{
      cursor: pointer;
      background-color: @lsv_green_active;
    }
  }
  @media screen and (min-width: 1025px) {
      display: none;
  }
  @media screen and (max-width: 361px) {
      width: 100%;
  }
}
#lsv-video-main,#lsv-video-main-tablet{
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  // background: url(../img/vids/poster.jpg) no-repeat;
  // background-size: cover; 
  @media screen and (max-width: 760px){
    display: none;
  }
}
// #lsv-video-main{
//   @media screen and (max-width: 1100px){
//     display: none;
//   }
// }
// #lsv-video-main-tablet{
//   @media screen and (min-width: 1100px){
//     display: none;
//   }
// }
button.btn{
  &:focus{
    background-color: @lsv_green_active;
    color: white;
  }
  &:hover{
    color: white;
  }
}
.products__approved-anim-block{
  // padding-top: @lsv_menu_height;
  position: absolute;
  width: 100%;
  height: 50%;
  label, .lsv-production__text-block{
    color: @lsv_grey_very_dark;
  }
  label{
    top: 0;
    left: 0;
  }
  .lsv-dairy-products__next-animation{
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    width: 150px;
  }
  #lsv-dairy-products__next-animation{
    bottom: -20px;
    display: block;
  }
  .animation-container{
    position: absolute;
    right: 0;
    left: 0;
    margin: auto;
    height: 72%;
    @media screen and (max-width: 992px){
      left: 13%;
      width: 50%;
      height: 70%;
      margin: 0;
    }
    @media screen and (max-width: 768px){
      left: 3%;
      width: 60%;
      height: 60%;
      margin: 0;
    }
    @media screen and (max-width: 580px){
      right: 0;
      left: 0;
      top: 190px;
      margin: auto;
      width: 100%;
      height: 70%;
    }
  }
  .lsv-production__text-block{
    left: 65%;
    @media screen and (max-width: 1200px){
      left: 65%;
      width: 33%;
    }
    @media screen and (max-width: 1024px){
      left: 67%;
      width: 33%;
    }
    @media screen and (max-width: 768px){
      left: 66%;
      width: 33%;
    }
    @media screen and (max-width: 620px){
      left: 60%;
      width: 33%;
    }
    @media screen and (max-width: 580px){
      width: 100%;
      left: 0;
      top: @lsv_menu_height + 20px;
      padding: 0 30px;
    }
  }
  .lsv-h3{
    font-size: 24px;
  }
  @media screen and (max-width: 480px){
    height: 400px;
  }
}
.products__approved-text-block{
  // .col-md-8{
  //   max-width: 800px;
  // }
  // padding-top: 100%;
  // @media screen and (max-width: 768px){
  //   padding-top: 150%;
  // }
  // // @media screen and (max-width: 580px){
  // //   padding-top: 750px;
  // // }
}
.manufacturers,.customers{
  padding: 30px 0;
  .lsv-h3{
    font-size: 18px;
  }
  p{
    margin-bottom: 20px;
  }
}
.customers{
  padding: 0;
  padding-top: 30px + @lsv_menu_height;
}
.lsv-vacancies__form-header{
  margin-top: @lsv_menu_height;
}
.lsv-main__mobile-start{
  // background-image: url(http://placehold.it/480x640);
  background-position: center;
  background-size: cover;
  height: 100%;
  width: 100%;
  h1{
    text-align: center;
    position: absolute;
    top: 40%;
    width: 100%;
    height: 100%;
    color:white;
  }
}
.lsv-main__mobile-seasons{
  text-align: center;
  margin: 40px 0;
  .lsv-main__season{
    display: inline-block;
    margin: 10px;
    span{
      display: block;
    }
  }
}
.lsv-main__mobile-season-discription{
  text-align: left;
  margin: 15px;
}

// PRODUCTION PRODUCTS ICON SLIDER

#rotate-block{
  display: none;
  position: absolute;
  top: 0;
  transform: rotate(90deg);
  transform-origin: 0 100%;
}

#lsv-production-product-icon-slider{
  width: 400px;
  height: 150px;
  background-color: white;
  padding: 27px;
  border-radius: 100px;
  margin-bottom: 50px;
  .item{
    transform: rotate(-90deg);
    text-align: center;
  }
  img {
    transform-origin: 50% 50%;
    width: 100px;
    height: 100px;
  }
  // #lsv-production-product-icon-slider{
  //     background-color: white;
  //     padding: 30px;
  //     border-radius:100px;
  // .owl-buttons{
  //     position: absolute;
  //     top: 50%;
  //     left: 0;
  //   }
  // }
  .owl-buttons div{
    position: absolute;
    opacity: 1; 
    border-radius: 1px;
    transform: rotate(270deg);
    background-color: transparent;
    background: url(../img/svg/arrow.svg);
    background-repeat: no-repeat;
    background-size: 30% 30%;
    background-position: center;
    transform: rotate(-90deg);
    right: -50px;
    top: 45px;
    width: 150px;
    height: 50px;
  }
  div.owl-prev{
    left: -50px;
    transform: rotate(90deg);
    // transform-origin: 50% 50%;
    // transform: rotate(180deg);
  }
  .owl-next {
    right: 0;
    top: 20px;
  }
  .owl-carousel .owl-wrapper, .owl-carousel .owl-item {
    transform: none;
  }
  .owl-item {
    transform: scale(0.7);
  }
  .scaleCircle {
    transform-origin: 50% 50%;  
    transform: scale(1);
    transition: 0.3s;
  }
}



//main animation
#left-block-option {         
  height: 100%;
  background: #efefef;
}

#right-block-svg {
  height: 100%;
  background: #46a4bb;            
}

.block-seasons-option {
  display: inline-block;
  padding: 10px;
  margin: 10px;
  text-align: center;
  @media screen and (max-width: 800px){
    margin: 0 20px;
  }
  @media screen and (max-width: 768px){
    margin: 0;
    padding: 5px 5px;
  }
  @media screen and (max-width: 600px){
    padding: 5px 0;
  }
}

#option-group {
  margin-top: 28%;
  text-align: center;
  img {
    margin-bottom: 25px;
  }
  hr {
    width: 370px;
    height: 1px;
    background: #d6d4d4;
  }
  @media screen and (max-width: 768px){
    margin-top: @lsv_menu_height + 20px;
  }
}
@media screen and (max-height: 768px){
  #option-group{
    hr{
      margin-top: 5px;
      margin-bottom: 5px;
    }
    .block-seasons-option{
      margin: 5px 10px 0;
    }
    .lsv-form__btn-submit{
      margin-top: 5px;
    }
    br{
      display: none;
    }
  }
}

.svg-option {
  cursor: pointer;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  transition: 0.3s;
}

.block-seasons-option:nth-child(3):hover .svg-option path{
  transition: 0.3s;
  fill: white;    
}


.block-seasons-option:nth-child(4):hover .svg-option{
  transition: 0.3s;
  fill: white;    
}

.block-seasons-option:nth-child(5):hover .svg-option{
  transition: 0.3s;
  fill: white;   
}

.block-seasons-option:nth-child(6):hover .svg-option{
  transition: 0.3s;
  fill: white;
}

.block-seasons-option:nth-child(3):hover .circle-svg{
  fill: #46a4bb;
  stroke: white;  
}

.block-seasons-option:nth-child(4):hover .circle-svg{
  fill: #ec5439;
  stroke: white;  
}

.block-seasons-option:nth-child(5):hover .circle-svg{
  fill: #fdcc38;
  stroke: white;  
}

.block-seasons-option:nth-child(6):hover .circle-svg{
  fill: #33799e;
  stroke: white;  
}

.block-leaves-option:nth-child(3):hover .svg-option{
  transition: 0.3s;
  fill: white;   
}

.block-leaves-option:nth-child(4):hover .svg-option{
  transition: 0.3s;
  fill: white;    
}

.block-leaves-option:nth-child(5):hover .svg-option{
  transition: 0.3s;
  fill: white;   
}

.block-leaves-option:nth-child(3):hover .circle-svg{
  fill: #379d19;
   stroke: white;  
}

.block-leaves-option:nth-child(4):hover .circle-svg{
  fill: #6cc053;
   stroke: white;  
}

.block-leaves-option:nth-child(5):hover .circle-svg{
  fill: #8de546;
   stroke: white;  
}

//active
.block-seasons-option:nth-child(3).active .svg-option path{
  transition: 0.3s;
  fill: white;    
}


.block-seasons-option:nth-child(4).active .svg-option{
  transition: 0.3s;
  fill: white;    
}

.block-seasons-option:nth-child(5).active .svg-option{
  transition: 0.3s;
  fill: white;   
}

.block-seasons-option:nth-child(6).active .svg-option{
  transition: 0.3s;
  fill: white;
}

.block-seasons-option:nth-child(3).active .circle-svg{
  fill: #46a4bb;
  stroke: white;  
}

.block-seasons-option:nth-child(4).active .circle-svg{
  fill: #ec5439;
  stroke: white;  
}

.block-seasons-option:nth-child(5).active .circle-svg{
  fill: #fdcc38;
  stroke: white;  
}

.block-seasons-option:nth-child(6).active .circle-svg{
  fill: #33799e;
  stroke: white;  
}

.block-leaves-option:nth-child(3).active .svg-option{
  transition: 0.3s;
  fill: white;   
}

.block-leaves-option:nth-child(4).active .svg-option{
  transition: 0.3s;
  fill: white;    
}

.block-leaves-option:nth-child(5).active .svg-option{
  transition: 0.3s;
  fill: white;   
}

.block-leaves-option:nth-child(3).active .circle-svg{
  fill: #379d19;
   stroke: white;  
}

.block-leaves-option:nth-child(4).active .circle-svg{
  fill: #6cc053;
   stroke: white;  
}

.block-leaves-option:nth-child(5).active .circle-svg{
  fill: #8de546;
   stroke: white;  
}

.block-leaves-option {
  display: inline-block;
  padding: 10px;
  margin: 5px;
  text-align: center;
  @media screen and (max-width: 600px){
    padding: 1px;
    margin: 5px 0;
  }
  @media screen and (max-width: 768px){
    padding: 3px;
    margin: 5px 0;
  }
}

#text-right-block-svg {
  
  height: 100%;
  width: 40%;
  display: inline-block;
  position: relative;
  div {
    color: white;
    font-size: 17px;
    position: absolute;
    top: 45%;
    left: 20px;
    height: 30%;
    width: 120%;
    @media screen and (max-width: 768px){
      top: 30%;
    }
    @media screen and (max-width: 600px){
      width: 90%;
    }
  }
  @media screen and (max-width: 600px){
    height: 30%;
    width: 100%;
  }
}

#svg-text-right-block-svg {
  width: 59%;
  height: 100%;
  padding-top: @lsv_menu_height;
  @media screen and (max-width: 600px){
    height: 60%;
    width: 100%;
    padding-top: @lsv_menu_height/2;
  }
}

.block-seasons-option img {
  cursor: pointer;
  border-radius: 50%;
  transition: 0.3s; 
}

.block-seasons-option img:hover {
  background: @lsv_green;
}

.winter-color {
   -webkit-transition: all 0.5s ease;
   -moz-transition: all 0.5s ease;
   -o-transition: all 0.5s ease;
   transition: all 0.5s ease;
  fill: #bbb2d7;
}

.summer-color {
   -webkit-transition: all 0.5s ease;
   -moz-transition: all 0.5s ease;
   -o-transition: all 0.5s ease;
   transition: all 0.5s ease;
  fill: #8879b4;
}

.spring-color {
   -webkit-transition: all 0.5s ease;
   -moz-transition: all 0.5s ease;
   -o-transition: all 0.5s ease;
   transition: all 0.5s ease;
  fill: #9b8ec2;
}

.autumn-color {
   -webkit-transition: all 0.5s ease;
   -moz-transition: all 0.5s ease;
   -o-transition: all 0.5s ease;
   transition: all 0.5s ease;
  fill: #4e3b86;
}

.maryannik-color {
  fill: #d2f2fc;
   -webkit-transition: all 0.5s ease;
   -moz-transition: all 0.5s ease;
   -o-transition: all 0.5s ease;
   transition: all 0.5s ease;
}

.molochai-color {
  fill: #ffd7d7;
   -webkit-transition: all 0.5s ease;
   -moz-transition: all 0.5s ease;
   -o-transition: all 0.5s ease;
   transition: all 0.5s ease;
}

.reven-color {
  fill: #fbf5d0;
   -webkit-transition: all 0.5s ease;
   -moz-transition: all 0.5s ease;
   -o-transition: all 0.5s ease;
   transition: all 0.5s ease;
}

.text-option {
  text-align: left;
  padding-left: 17%;
}

@media screen and (max-width: 1224px) {
  .text-option {
    padding-left: 10%;
  }
}

@media screen and (max-width: 800px) {
  .text-option {
    padding-left: 0;
    text-align: center;
  }
}

//Main page game block

#header-season {
  margin-top: -50px;
  color: #61bb46;
  font-size: 20px;
  text-align: left;
  padding-left: 17%;
}

#svg-text-right-block-svg {
  width: 100%;
  height: 99%;
}

 #block-button-anim {
  width: 100%;
  height: 64px;
  background: #79d35e;
  position: absolute;
  bottom: 0px;
}

#block-button-anim a {
  text-decoration: none;
}

#block-button-anim div {
  cursor: pointer;
  padding-top: 18px;
  text-align: center;
  display: inline-block;
  width: 33%;
  height: 100%;
  color: white;
  font-size: 20px;
  transition: 0.3s;
}

#block-button-anim div:hover {
  background: #61bb46;
  transition: 0.3s;
}

@media screen and (max-width: 1000px) {
    #block-button-anim {
      height: 150px;
      position: absolute;
    }

    #block-button-anim div {
      border-top: 1px solid #e5e5e5;
      padding-top: 10px;
      padding-bottom: 38px;
      display: block;
      width: 100%;
      height: 22%;
    }

    #left-block-option {
      padding-top: 50px;
    }
}

@media screen and (max-width: 767px) {
    #block-button-anim {
      position: static;
    }
}
#lsv-main_video-text{
  div{
    height: 100%;
    width: 100%;
  }
  h1{
    text-transform: uppercase;
    font-size: 40px;
    color: white;
    margin: 0;
    font-weight: 900;
  }
  .slogan_1>h1{
    margin: 200px;
  }
  .slogan_2>h1{
    
  }
  .slogan_3>h1{
    
  }
  @media screen and (max-width: 480px){
    display: none!important;
  }
}

#lsv-shop__skip-map-btn{
  margin: 0;
  margin-top: -35px;
  position: relative;
  left: 105px;
  @media screen and (max-width: 768px){
    left: 50px;
  }
  @media screen and (max-width: 666px){
    display: none;
  }
}
.lsv-main-email{
  text-transform: lowercase;
}
.lsv-contacts{
  .col-md-3{
    padding-right: 20px;
  }
  .padding-0-40{
    @media screen and (max-width: 420px){
      padding-left: 10px;
      padding-right: 10px;
    }
    p,h2{
      @media screen and (max-width: 420px){
        padding-right: 30px;
      }
    }
  }
  .margint-80{
    @media screen and (max-width: 420px){
      margin-top: 20px;
    }
  }
  .padding-40pmh{
    @media screen and (max-width: 420px){
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
.lsv-contacts__department{
  &.active .lsv-contacts__toggle{
    display: block;
  }
  &:after{
    content: "";
    display: none;
    width: 45px;
    height: 45px;
    background-image: url(../img/svg/slider-arrow-circle.svg);
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 0;
    right: 0px;
    transform: rotate(-90deg);
    transition: all 300ms cubic-bezier(.215,.61,.355,1);
    @media screen and (max-width: 420px){
      display: block;
    }
  }
  &.active:after{
    transform: rotate(90deg);
  }
  @media screen and (max-width: 420px){
    cursor: pointer;
  }
  .col-md-6{
    min-height: 150px;
  }
}
.lsv-contacts__toggle{
  @media screen and (max-width: 420px){
    display: none;
  }
}
#lsv-dairy-products__next-animation {
    top: initial;
    bottom: -30px;
    left: 0;
    right: 0;
    margin: auto;
    width: 180px;
    text-align: center;
    &.active{
      label{
        top: 0;
      }
    }
    @media screen and (max-width: 768px){
      bottom: 0;
    }
}
.lsv-dairy-products__list-item:nth-child(18){
  // color: red;
  // height: 70px;
}
#lsv-shop__down-btn{
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 10px;
  transform: rotate(-90deg);
  cursor:pointer;
  // display: none;

  // @media screen and (max-width: 768px){
  //   display: block;
  // }
}
.lsv-pluses__header{
  margin-bottom: 150px;
  @media screen and (max-width: 1280px){
    margin: 50px 0;
  }
}
.lsv-pluses__container{
  div{
    min-height: 220px;
    @media screen and (max-width: 768px){
      margin-bottom: 50px;
    }
  }
  div:first-child{
    margin-top: -7px;
  }
  padding: 0 40px;
  p{
    text-transform: uppercase;
    line-height: 20px;
    margin-top: 50px;
  }
}
//../img/svg/slider-arrow-circle.svg
.lsv-slides-controls{
  div{
    position: fixed;
    right: 20px;
    width: 50px;
    height: 50px;
    z-index: 2004;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(../img/svg/slider-arrow-circle.svg);
    cursor: pointer;
    transition: all 200ms linear;
    opacity: 0;
    &.active{
      opacity: 1;
    }
  }
  @media screen and (max-width: 768px){
    display: none;
  }
}
#lsv-slide-up{
  top: @lsv_menu_height+20px;
  transform: rotate(90deg);
}
#lsv-slide-down{
  bottom: 20px;
  transform: rotate(-90deg);
}
.lsv-vacancies{
  @media screen and (max-width: 480px){
    .section:first-child{
      display: none;
    }
  }
}
.lsv-vacancies__form{
  .lsv-btn__input-file, .help-block, .bootstrap-filestyle{
    @media screen and (max-width: 480px){
      display: none;
    }
  }
}
#lsv-production__product-case-slider{
  position: absolute;
  top: @lsv_menu_height*2+50px;
  z-index: 1031;
  img{
    max-height: 400px;
    min-height: 300px;
  }
  .owl-buttons{
    // position: absolute;
    top: 0;
    // height: 100%;
    // width: 100%;
    div{
      margin: 0;
      height: 100%;
      color: transparent;
      background-color: transparent;
      background-position:center;
      background-repeat: no-repeat;
      opacity: 1;
      background-image: url('../img/svg/slider-arrow-circle-inverse.svg');
      position: absolute;
      &:hover{
        opacity: 0.7;
      }
    }
    .owl-prev{
      left: 0;
      top: 0;
    }
    .owl-next{
      top: 0;
      right: 0;
      transform-origin: 50% 50%;
      transform: rotate(180deg);
    }
  }
  .owl-controls .owl-page span{
    background-color: white;
  }
  .owl-controls{
    margin: 0;
  }
}
.lsv-main__learn-more{
  text-align: center;
  p{
    padding: 0 20px;
  }
  a{
    display: inline-block;
  }
}
#notDownload {
    background-color: @lsv_grey_very_dark;
    color: white;
    width: 260px;
    border-radius: 4px;
    text-align: center;
    position: absolute;
    display: none;
    opacity: 0.8;
    padding: 5px 10px;
    z-index: 3000;
}
.lsv-off-logo{
  img{
    height: 169px;
  }
  @media screen and (max-width: 992px){
    margin-top: 25px;
  }
}

// @-webkit-keyframes slide-arrow-down {
//   from {bottom: 20px}
//   to {bottom: -40px}
// }
// @-o-keyframes slide-arrow-down {
//   from {bottom: 20px}
//   to {bottom: -40px}
// }
// @-moz-keyframes slide-arrow-down {
//   from {bottom: 20px}
//   to {bottom: -40px}
// }
// @keyframes slide-arrow-down {
//   from {bottom: 20px}
//   to {bottom: -40px}
// }

.lsv-helper__arrow-down{
  text-align: center;
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  width: 200px;
  margin: auto;
  // animation: slide-arrow-down 1s linear infinite;
  img{
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    position: relative;
    top: -15px;
    // margin-right: 20px;
  }
  .lsv-btn{
    width: initial;
    padding: 10px 20px;
    cursor: pointer;
    &:hover{
      background-color: @lsv_green;
    }
  }
  @media screen and (max-width: 768px){
    display: none;
  }
  &.right{
    position: fixed;
  }
}
.lsv-article__date{
  margin: 10px 0 30px 0;
  display: block;
}
#ferm {
  position: relative;
  height: 50%;
}
#lsv-main__opacity-block {
  z-index: 9999;
  position: fixed;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: none;
}
#opacity-block--popup {
  z-index: inherit;
  width: 450px;
  height: 300px;
  background: #efefef;
  outline: 5px solid #e1e1e1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: 10px 80px;
  color: #515151;
}
#opacity-block--popup h1 {
  font-weight: 700; 
  color: #61bb46;
  font-size: 20px;
  margin-bottom: 10px;
}
.lsv-btn__btn-popup {
  margin: 5px;
  width: 200px;
  position: relative;
  left: 12%;
}
.opacity-block--popup-scaleIn {
  transform: scale(1);
  transition: 0.2s;
}
.opacity-block--popup-scaleOut {
  transform: scale(0);
  transition: 0.2s;
}
.animation-container{
  white-space: nowrap;
}
.prev{
  transform-origin:50% 50%;
  transform:rotate(180deg);
}
.lsv-question{
  min-height: 300px;
  @media screen and (max-width: 992px){
    min-height: initial;
    margin-bottom: 15px;
  }
}
.lsv-about__about-block>.about-text-block{
  // max-height: 100px;
  // overflow-y: scroll;
  // &.show{
    
  // }
}
.lsv-contacts__form{
  @media screen and (max-width: 992px){
    margin-top: 75px;
  }
  @media screen and (max-width: 768px){
    margin-top: 40px;
  }
}
.lsv-animation-section{
  @media screen and (max-width: 768px){
    min-height: 600px;
  }
}
#vk_likes{
  position: absolute;
  bottom: 20px;
  right: 60px;
  @media screen and (max-width: 480px){
    right: 13px;
    top: 180px;
  }
}
.vk-like{
  display: none;
}

//ANIMATIONS
@-webkit-keyframes grow{
  0% {
    -webkit-transform: scale(0.3);
    fill: green;
  }
  30% {
    -webkit-transform: scale(1);
    fill: green;
  }
  60% {
    fill: #CE1700;
    -webkit-transform: scale(1.2);
  }
  100% {
    fill: #CE1700;
    -webkit-transform: scale(1.2);
  }
}
@keyframes grow{
  0% {
    transform: scale(0.3);
    fill: green;
  }
  30% {
    transform: scale(1);
    fill: green;
  }
  60% {
    fill: #CE1700;
    transform: scale(1.2);
  }
  100% {
    fill: #CE1700;
    transform: scale(1.2);
  }
}

@-webkit-keyframes breeze{
  0% {
    -webkit-transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(10deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}
@keyframes breeze{
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@-webkit-keyframes peck-right{
  0% {
    -webkit-transform: rotate(0deg) translateY(0px) translateX(0px);
  }
  50% {
    -webkit-transform: rotate(70deg) translateY(40px) translateX(-30px);
  }
  100% {
    -webkit-transform: rotate(0deg) translateY(0px) translateX(0px);
  }
}
@-webkit-keyframes blinks{
  0% {
    -webkit-transform: translateX(0px);
  }
  50% {
    -webkit-transform: translateX(10px);
  }
  100% {
    -webkit-transform: translateX(0px);
  }
}
@keyframes blinks{
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0px);
  }
}

@-webkit-keyframes move-top10{
  0% {
    -webkit-transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(-20px);
  }
  100% {
    -webkit-transform: translateY(0px);
  }
}
@keyframes move-top10{
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}
@-webkit-keyframes move-top20{
  0% {
    -webkit-transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(-20px);
  }
  100% {
    -webkit-transform: translateY(0px);
  }
}
@keyframes move-top20{
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}
@-webkit-keyframes rotate10{
  0% {
    -webkit-transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(5deg);
  }
  /*50% {
    -webkit-transform: rotate(0deg);
  }*/
  75% {
    -webkit-transform: rotate(-5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}
@keyframes rotate10{
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(5deg);
  }
  /*50% {
    -webkit-transform: rotate(0deg);
  }*/
  75% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@media screen and (min-width: 768px){
  .st112{
    -webkit-transform-origin: 50% 50%;
    -webkit-animation: grow 10s ease-out infinite;
    transform-origin: 50% 50%;
    animation: grow 10s ease-out infinite;
  }
  .tree{
    -webkit-transform-origin: 50% 100%;
    -webkit-animation: breeze 3s ease-out infinite;
    transform-origin: 50% 100%;
    animation: breeze 3s ease-out infinite;
  }
  #chiken_yellow{
    -webkit-transform-origin: 50% 100%;
    -webkit-animation: peck-right 2s ease-out infinite;
    transform-origin: 50% 100%;
    animation: peck-right 2s ease-out infinite;
  }

  #berries_1>circle{
    -webkit-transform-origin: 50% 50%;
    -webkit-animation: grow 8s ease-out infinite;
    -webkit-animation-delay: 1000ms;
    transform-origin: 50% 50%;
    animation: grow 8s ease-out infinite;
    animation-delay: 1000ms;
  }
  #plants_1_>g{
    -webkit-transform-origin: 50% 50%;
    -webkit-animation: grow 8s ease-out infinite;
    -webkit-animation-delay: 3000ms;
    transform-origin: 50% 50%;
    animation: grow 8s ease-out infinite;
    animation-delay: 3000ms;
  }
  #bull_1_tail{
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-animation: rotate10 3s ease-out infinite;
    animation: rotate10 3s ease-out infinite;
  }
  #bull_2_tail{
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-animation: rotate10 3s ease-out infinite;
    animation: rotate10 3s ease-out infinite;
  }
  #cow_1_head{
    -webkit-transform-origin: 50% 50%;
    -webkit-animation: move-top10 3s ease-out infinite;
    transform-origin: 50% 50%;
    animation: move-top10 3s ease-out infinite;
  }
  #cow_2_head{
    -webkit-transform-origin: 50% 50%;
    -webkit-animation: move-top10 3s ease-out infinite;
    -webkit-animation-delay: 1500ms;
    transform-origin: 50% 50%;
    animation: move-top10 3s ease-out infinite;
    animation-delay: 1500ms;
  }
  #cow_1_tonque{
    -webkit-transform-origin: 50% 50%;
    -webkit-animation: move-top20 3s ease-out infinite;
    transform-origin: 50% 50%;
    animation: move-top20 3s ease-out infinite;
  }
  #soon_1,#soon_2,#soon_3{
    -webkit-transform-origin: 50% 100%;
    -webkit-animation: rotate10 5s ease-out infinite;
    -webkit-animation-delay: 1500ms;
    transform-origin: 50% 100%;
    animation: rotate10 5s ease-out infinite;
    animation-delay: 1500ms;
  }
  #river_lights>path:nth-child(odd){
    -webkit-transform-origin: 50% 50%;
    -webkit-animation: blinks 3000ms ease-out infinite;
    transform-origin: 50% 50%;
    animation: blinks 3000ms ease-out infinite;
  }
  #river_lights>path:nth-child(even){
    -webkit-transform-origin: 50% 50%;
    -webkit-animation: blinks 3000ms ease-out infinite;
    -webkit-animation-delay: 500ms;
    transform-origin: 50% 50%;
    animation: blinks 3000ms ease-out infinite;
    animation-delay: 500ms;
  }
}
#lsv-vacancies-form-btn{
  margin-bottom: 0;
  width: 223px;
}
#lsv-vacancies-menu-btn{
  margin: 38px 0 0;
  width: 223px;
}
.lsv-membership{
  padding-left: 20px;
  &>div:first-child{
    margin-bottom: 24px;
  }
  &--mobile{
    display: none;
    padding: 0;
    margin: 20px auto;
    text-align: center;
    @media screen and (max-width: 992px){
      display: block;
    }
  }
}

@-webkit-keyframes opacity {
  from { opacity: 0 }
  to { opacity: 1 }
}

@keyframes opacity {
  from { opacity: 0 }
  to { opacity: 1 }
}

#loading{
  color: @lsv_green;
  position: absolute;
  left: 30px;
  .points{
    display: inline-block;
  }
  .point{
    width: 9px;
    height: 9px;
    margin: 0 0px;
    color: @lsv_green;
    border-radius: 50%;
    display: inline-block;
    background-color: @lsv_green;
    opacity: 0;
    animation: opacity 1s linear 0s infinite alternate;
    &:nth-child(2){
      animation-delay: 333ms;
    }
    &:nth-child(3){
      animation-delay: 666ms;
    }
    // animation-delay: 2s;
  }
}
.lsv-shops__list{
  #loading{
    top: 30px;
    left: 33px;
  }
  &.loading{
    .lsv-shops__list-item{
      display: none;
    }
  }
}
.fp-section.fp-table, .fp-slide.fp-table{
  max-width: 100vw;
  overflow: hidden;
}